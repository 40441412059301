import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import baseQueryWithToken from "src/features/helper/apiHeader/ApiHeader";


export const FastagAPISlice = createApi({
    reducerPath: "FastagAPI",
    baseQuery: baseQueryWithToken,

    endpoints: (builder) => ({
        getAllFastag: builder.query({
            query: () => "/admin/lead-services/fastag/fastag",
            providesTags: ['Fastag']
        }),
        createFastag: builder.mutation({
            query: (fastagDetails) => (console.log("\n fastagDetails >>>>> ", fastagDetails), {
                url: '/admin/lead-services/fastag/create-fastag',
                method: "POST",
                body: fastagDetails
            }),
            invalidatesTags: ['Fastag']
        }),
        updateFastag: builder.mutation({
            query: (update) => (console.log("\n updateFastag >>> ", update), {
                url: `/admin/lead-services/fastag/update-fastag/${update.id}`,
                method: "PATCH",
                body: update.data
            }),
            invalidatesTags: ['Fastag']
        }),
        getSingleFastag: builder.query({
            query: (id) => `/admin/lead-services/fastag/single-fastag/${id}`,
        }),
    })
})

export const {
    useGetAllFastagQuery,
    useCreateFastagMutation,
    useUpdateFastagMutation,
    useGetSingleFastagQuery

} = FastagAPISlice
import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithToken from "src/features/helper/apiHeader/ApiHeader";


export const AppSettingAPISlice = createApi({
    reducerPath : "AppSettingAPI",
    baseQuery: baseQueryWithToken,

    endpoints: (builder) => ({
        getAppSetting : builder.query({
            query : () => `/admin/appSetting/get-appSetting`,
            providesTags : ['AppSetting']
        }),
        addAppSetting: builder.mutation({
            query : (updatedAppSettingData) => ({
                url : '/admin/appSetting/add-appSetting',
                method : "POST",
                body : updatedAppSettingData
                // customerCareNumber,
                // customerCareEmail,
                // customerCareWhatsApp,
                // termAndCondition,
                // privacyPolicy
            }),
            invalidatesTags : ['AppSetting']
        }),
    })
})

export const {
    useGetAppSettingQuery,
    useAddAppSettingMutation
} = AppSettingAPISlice;
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import baseQueryWithToken from "src/features/helper/apiHeader/ApiHeader";


export const HomeLoanAPISlice = createApi({
    reducerPath: "HomeLoanAPI",
    baseQuery: baseQueryWithToken,

    endpoints: (builder) => ({
        getAllHomeLoan: builder.query({
            query: () => "/admin/lead-services/home-loan/home-loans",
            providesTags: ["HomeLoan"]
        }),
        createHomeLoan: builder.mutation({
            query: (homeLoanDetails) => (console.log("\n homeLoanDetails >>>>> ", homeLoanDetails), {
                url: '/admin/lead-services/home-loan/create-home-loan',
                method: "POST",
                body: homeLoanDetails
            }),
            invalidatesTags: ['HomeLoan']
        }),
        updateSellServicesImage: builder.mutation({
            query: (data) => ({
                url: `/admin/lead-services/update-sell-service-image/${data.id}`,
                method: "PATCH",
                body: data.uploadImageData
            }),
            invalidatesTags: ['SellServices']
        }),
        updateHomeLoan: builder.mutation({
            query: (update) => (console.log("\n updateHomeLoan >>> ", update), {
                url: `/admin/lead-services/home-loan/update-home-loan/${update.id}`,
                method: "PATCH",
                body: update.data
            }),
            invalidatesTags: ['HomeLoan']
        }),
        deleteHomeLoan: builder.mutation({
            query: (id) => ({
                url: `/admin/lead-services/home-loan/delete-home-loan/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["HomeLoan"]
        }),
        addCreditCardFAQ: builder.mutation({
            query: (createCreditCardfaq) => ({
                url: `/admin/lead-services/credit-card/add-faq/${id}`,
                method: "POST",
                body: createCreditCardfaq
            }),
            invalidatesTags: ['CreditCards']
        }),
        getgetSingleHomeLoan: builder.query({
            query: (id) => `/admin/lead-services/home-loan/single-home-loan/${id}`,
        }),
    })
})

export const {
    useGetAllHomeLoanQuery,
    useGetgetSingleHomeLoanQuery,
    useDeleteHomeLoanMutation,
    useCreateHomeLoanMutation,
    useUpdateHomeLoanMutation

} = HomeLoanAPISlice
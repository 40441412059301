import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { OnboardingAPISlice } from "./features/integrateAPI/onboarding/OnboardingAPISlice";
import { UpComingServiceAPISlice } from "./features/integrateAPI/upcomingService/UpComingServiceAPISlice";

import sidebarSlice from "./features/sidebarSlice";
import { GenerateTokenAPISlice } from "./features/integrateAPI/generateToken/GenerateTokenAPISlice";
import TokenSlice from "./features/tokenSlice/TokenSlice";
import { OfferAPISlice } from "./features/integrateAPI/offer/OfferAPISlice";
import { SellServicesAPISlice } from "./features/integrateAPI/sellServices/SellServicesAPISlice";
import { BasicInfoAPISlice } from "./features/integrateAPI/basicInfo/BasicInfoAPISlice";
import valueSlice from "./features/valueSlice";
import LeadServicesAPISlice from "./features/integrateAPI/LeadServices/LeadServicesAPISlice";
import { CreditCardsAPISlice } from "./features/integrateAPI/LeadServices/creditCards/CreditCardsAPISlice";
import { MotorInsuranceAPISlice } from "./features/integrateAPI/LeadServices/motorInsurance/MotorInsuranceAPISlice";
import { CarLoanAPISlice } from "./features/integrateAPI/LeadServices/carLoan/CarLoanAPISlice";
import { HomeLoanAPISlice } from "./features/integrateAPI/LeadServices/homeLoan/HomeLoanAPISlice";
import { BusinessLoanAPISlice } from "./features/integrateAPI/LeadServices/businessLoan/BusinessLoanAPISlice";
import { PersonalLoanAPISlice } from "./features/integrateAPI/LeadServices/personalLoan/PersonalLoanAPISlice";
import { FastagAPISlice } from "./features/integrateAPI/LeadServices/fastag/FastagAPISlice";
import { SliderAPISlice } from "./features/integrateAPI/slider/SliderAPISlice";
import { MediaAPISlice } from "./features/integrateAPI/Media/MediaAPISlice";
import { WithDrawalAPISlice } from "./features/integrateAPI/withDrawal/WithDrawalAPISlice";
import UserDetails from "./views/user/userDetails/UserDetails";
import { UserAPISlice } from "./features/integrateAPI/user/UserAPISlice";
import { LeadAPISlice } from "./features/integrateAPI/lead/LeadAPISlice";
import { AppSettingAPISlice } from "./features/integrateAPI/appSetting/AppSettingAPISlice";
import { ProfileAPISlice } from "./features/integrateAPI/profile/ProfileAPISlice";
import { posterAPISlice } from "./features/integrateAPI/posters/posterAPISlice";
import { brochureAPISlice } from "./features/integrateAPI/brochures/brochureAPISlice";


export const store = configureStore({
  reducer: {

    sidebar: sidebarSlice,
    values: valueSlice,
    generatetoken: TokenSlice,
    [OnboardingAPISlice.reducerPath]: OnboardingAPISlice.reducer,
    [GenerateTokenAPISlice.reducerPath]: GenerateTokenAPISlice.reducer,
    [OfferAPISlice.reducerPath]: OfferAPISlice.reducer,
    [SellServicesAPISlice.reducerPath]: SellServicesAPISlice.reducer,
    [BasicInfoAPISlice.reducerPath]: BasicInfoAPISlice.reducer,
    [SliderAPISlice.reducerPath]: SliderAPISlice.reducer,
    [MediaAPISlice.reducerPath]: MediaAPISlice.reducer,
    [WithDrawalAPISlice.reducerPath]: WithDrawalAPISlice.reducer,
    [UserAPISlice.reducerPath]: UserAPISlice.reducer,
    [LeadAPISlice.reducerPath]: LeadAPISlice.reducer,
    [AppSettingAPISlice.reducerPath]: AppSettingAPISlice.reducer,
    [ProfileAPISlice.reducerPath]: ProfileAPISlice.reducer,
    [posterAPISlice.reducerPath]: posterAPISlice.reducer,
    [brochureAPISlice.reducerPath]: brochureAPISlice.reducer,



    // lead service api
    [CreditCardsAPISlice.reducerPath]: CreditCardsAPISlice.reducer,
    [MotorInsuranceAPISlice.reducerPath]: MotorInsuranceAPISlice.reducer,
    [CarLoanAPISlice.reducerPath]: CarLoanAPISlice.reducer,
    [HomeLoanAPISlice.reducerPath]: HomeLoanAPISlice.reducer,
    [BusinessLoanAPISlice.reducerPath]: BusinessLoanAPISlice.reducer,
    [PersonalLoanAPISlice.reducerPath]: PersonalLoanAPISlice.reducer,
    [FastagAPISlice.reducerPath]: FastagAPISlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      OnboardingAPISlice.middleware,
      GenerateTokenAPISlice.middleware,
      OfferAPISlice.middleware,
      SellServicesAPISlice.middleware,
      UpComingServiceAPISlice.middleware,
      BasicInfoAPISlice.middleware,
      SliderAPISlice.middleware,
      MediaAPISlice.middleware,
      WithDrawalAPISlice.middleware,
      UserAPISlice.middleware,
      LeadAPISlice.middleware,

      AppSettingAPISlice.middleware,
      ProfileAPISlice.middleware,
      posterAPISlice.middleware,
      brochureAPISlice.middleware,


      // lead services middleware
      CreditCardsAPISlice.middleware,
      MotorInsuranceAPISlice.middleware,
      CarLoanAPISlice.middleware,
      HomeLoanAPISlice.middleware,
      BusinessLoanAPISlice.middleware,
      PersonalLoanAPISlice.middleware,
      FastagAPISlice.middleware
    )
})

setupListeners(store.dispatch)

export default store;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import baseQueryWithToken from "src/features/helper/apiHeader/ApiHeader";



export const posterAPISlice = createApi({
    reducerPath: "posterAPI",
    baseQuery: baseQueryWithToken,

    endpoints: (builder) => ({
        getPoster: builder.query({
            query: () => "/admin/media/get-posters",
            providesTags: ['poster']
        }),
        addPoster: builder.mutation({
            query: (posterData) => (console.log("\n addPoster ?? ", posterData), {
                url: '/admin/media/media-document',
                method: "POST",
                body: posterData
            }),
            invalidatesTags: ['poster']
        }),
        delatePoster: builder.mutation({
            query: (id) => ({
                url: `/admin/media/media-document/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['poster']
        }),
        updatePosterInfo: builder.mutation({
            query: (updateData) => (console.log("\n check  updatePosterInfo >>>> ", updateData), {
                url: `/admin/media/media-document/${updateData?.id}`,
                method: "PATCH",
                body: updateData?.data
            }),
            invalidatesTags: ['poster']
        }),
    })
})

export const {
    useGetPosterQuery,
    useAddPosterMutation,
    useUpdatePosterInfoMutation,
    useDelatePosterMutation
} = posterAPISlice
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import baseQueryWithToken from "src/features/helper/apiHeader/ApiHeader";


export const SellServicesAPISlice = createApi({
    reducerPath : "SellServicesAPI",
    baseQuery: baseQueryWithToken,

    endpoints: (builder) => ({
        getSellServices: builder.query({
            query : () => "admin/lead-services/sell-services",
            providesTags : ['SellServices']
        }),
        addSellServices: builder.mutation({
            query : (onboardingData) => ({
                url : '/admin/lead-services/create-sell-service',
                method : "POST",
                body : onboardingData
            }),
            invalidatesTags : ['SellServices']
        }),
        updateSellServicesImage: builder.mutation({
            query: (data) => ({
                url : `/admin/lead-services/update-sell-service-image/${data.id}`,
                method : "PATCH",
                body: data.uploadImageData
            }),
            invalidatesTags : ['SellServices']
        }),
        updateSellServicesInfo : builder.mutation({
            query: (update) => ({
                url : `admin/lead-services/update-sell-service-info`,
                method : "PATCH",
                body: update
                // {
                //     "sellServiceId": "659c13a1a0c03ef3cff1cca8",
                //     "title": "Sell Service",
                //     "tag": "credit-card",
                //     "description": "Description!!!!"
                // }
            }),
            invalidatesTags : ['SellServices']
        }),
        delateSellServices: builder.mutation({
            query: (id) => ({
                url : `admin/lead-services/delete-sell-service/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags : ['SellServices']
        }) 
    })
})

export const {
    useGetSellServicesQuery,
    useAddSellServicesMutation,
    useUpdateSellServicesImageMutation,
    useUpdateSellServicesInfoMutation,
    useDelateSellServicesMutation
} = SellServicesAPISlice
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import baseQueryWithToken from "src/features/helper/apiHeader/ApiHeader";


export const CreditCardsAPISlice = createApi({
    reducerPath: "CreditCardsAPI",
    baseQuery: baseQueryWithToken,

    endpoints: (builder) => ({
        getAllCreditCards: builder.query({
            query: () => "/admin/lead-services/credit-card/credit-cards",
            providesTags: ['CreditCards']
        }),
        createCreditCard: builder.mutation({
            query: (createCreditCardDetils) => (console.log("\n\n createCreditCardDetils  >>>>> ", createCreditCardDetils), {
                url: '/admin/lead-services/credit-card/create-credit-card',
                method: "POST",
                body: createCreditCardDetils
            }),
            invalidatesTags: ['CreditCards']
        }),
        updateSellServicesImage: builder.mutation({
            query: (data) => ({
                url: `/admin/lead-services/update-sell-service-image/${data.id}`,
                method: "PATCH",
                body: data.uploadImageData
            }),
            invalidatesTags: ['SellServices']
        }),
        updateCreditCard: builder.mutation({
            query: (update) => (console.log("\n\n updateCreditCard   >>>>> ", update), {
                url: `/admin/lead-services/credit-card/update-credit-card/${update.id}`,
                method: "PATCH",
                body: update.data
            }),
            invalidatesTags: ['CreditCards']
        }),
        deleteCreditCard: builder.mutation({
            query: (id) => ({
                url: `/admin/lead-services/credit-card/delete-credit-card/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['CreditCards']
        }),
        addCreditCardFAQ: builder.mutation({
            query: (createCreditCardfaq) => ({
                url: `/admin/lead-services/credit-card/add-faq/${id}`,
                method: "POST",
                body: createCreditCardfaq
            }),
            invalidatesTags: ['CreditCards']
        }),
        getSingleCreditCard: builder.query({
            query: (id) => `/admin/lead-services/credit-card/single-credit-card/${id}`,
        }),
        demoCreditCard: builder.mutation({
            query: (id) => ({
                url: `/admin/lead-services/credit-card/single-credit-card/${id}`,
                method: "GET",
            })
        }),
    })
})

export const {
    useGetAllCreditCardsQuery,
    useCreateCreditCardMutation,
    useUpdateCreditCardMutation,
    useDeleteCreditCardMutation,
    useGetSingleCreditCardQuery,
    useDemoCreditCardMutation
} = CreditCardsAPISlice
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import baseQueryWithToken from "src/features/helper/apiHeader/ApiHeader";


export const MediaAPISlice = createApi({
    reducerPath: "MediaAPI",
    baseQuery: baseQueryWithToken,

    endpoints: (builder) => ({
        getMediaList: builder.query({
            query: () => "/admin/media/get-short-videos",
            providesTags: ['Media']
        }),
        addMedia: builder.mutation({
            query: (mediaData) => ({
                url: '/admin/media/short-video',
                method: "POST",
                body: mediaData
                // for add
                // keyName = name, image, link, text
            }),
            invalidatesTags: ['Media']
        }),
        updateMedia: builder.mutation({
            query: (updatedMediaData) => ({
                url: `/admin/media/short-videos/${updatedMediaData?.id}`,
                method: "PATCH",
                body: updatedMediaData?.data
                // for update
                // keyname = sliderId, name, link, image, text
            }),
            invalidatesTags: ['Media']
        }),
        delateMedia: builder.mutation({
            query: (id) => ({
                url: `/admin/media/short-videos/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Media']
        }),

    })
})

export const {
    useGetMediaListQuery,
    useAddMediaMutation,
    useUpdateMediaMutation,
    useDelateMediaMutation
} = MediaAPISlice
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import baseQueryWithToken from "src/features/helper/apiHeader/ApiHeader";


export const SliderAPISlice = createApi({
    reducerPath: "SliderAPI",
    baseQuery: baseQueryWithToken,

    endpoints: (builder) => ({
        getSliderList: builder.query({
            query: () => "/admin/home/slider",
            providesTags: ['Slider']
        }),
        addSlider: builder.mutation({
            query: (sliderData) => ({
                url: '/admin/home/slider',
                method: "POST",
                body: sliderData
                // for add
                // keyName = name, image, link, text
            }),
            invalidatesTags: ['Slider']
        }),
        updateSlider: builder.mutation({
            query: (updatedSliderData) => ({
                url: '/admin/home/slider',
                method: "PATCH",
                body: updatedSliderData
                // for update
                // keyname = sliderId, name, link, image, text
            }),
            invalidatesTags: ['Slider']
        }),
        delateSlider: builder.mutation({
            query: (id) => ({
                url: `/admin/home/slider/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Slider']
        }),
        getSliderDetails: builder.query({
            query: (id) => `/admin/home/slider/${id}`,
        }),
    })
})

export const {
    useGetSliderListQuery,
    useGetSliderDetailsQuery,
    useAddSliderMutation,
    useUpdateSliderMutation,
    useDelateSliderMutation
} = SliderAPISlice
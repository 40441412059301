import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import baseQueryWithToken from "src/features/helper/apiHeader/ApiHeader";


export const MotorInsuranceAPISlice = createApi({
    reducerPath: "MotorInsuranceAPI",
    baseQuery: baseQueryWithToken,

    endpoints: (builder) => ({
        getAllMotorInsurance: builder.query({
            query: () => "/admin/lead-services/motor-insurance/motor-insurance",
            providesTags: ["MotorInsurance"]
        }),
        createMotorInsurance: builder.mutation({
            query: (motorDetails) => (console.log("\n\n motorDetails >>>>> ", motorDetails), {
                url: '/admin/lead-services/motor-insurance/create-motor-insurance',
                method: "POST",
                body: motorDetails
            }),
            invalidatesTags: ['MotorInsurance']
        }),
        updateSellServicesImage: builder.mutation({
            query: (data) => ({
                url: `/admin/lead-services/update-sell-service-image/${data.id}`,
                method: "PATCH",
                body: data.uploadImageData
            }),
            invalidatesTags: ['MotorInsurance']
        }),
        updateMotorInsurance: builder.mutation({
            query: (update) => (console.log("\n updateMotorInsurance >>>> ", update), {
                url: `/admin/lead-services/motor-insurance/update-motor-insurance/${update.id}`,
                method: "PATCH",
                body: update.data
            }),
            invalidatesTags: ['MotorInsurance']
        }),
        deleteCreditCard: builder.mutation({
            query: (id) => ({
                url: `/admin/lead-services/credit-card/delete-credit-card/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['MotorInsurance']
        }),
        addCreditCardFAQ: builder.mutation({
            query: (createCreditCardfaq) => ({
                url: `/admin/lead-services/credit-card/add-faq/${id}`,
                method: "POST",
                body: createCreditCardfaq
            }),
            invalidatesTags: ['MotorInsurance']
        }),
        getgetSingleMotorInsurance: builder.query({
            query: (id) => `/admin/lead-services/motor-insurance/motor-insurance-details/${id}`,
        }),
    })
})

export const {
    // useGetAllHomeLoanQuery,
    // useGetgetSingleHomeLoanQuery
    useGetAllMotorInsuranceQuery,
    useCreateMotorInsuranceMutation,
    useUpdateMotorInsuranceMutation,
    useGetgetSingleMotorInsuranceQuery,

} = MotorInsuranceAPISlice
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


const baseQueryWithToken = fetchBaseQuery({
    baseUrl: "https://api.rupaylender.com/",
    prepareHeaders: (headers, { getState }) => {
        const token = sessionStorage.getItem('token')
        if (token) {
            // Add the bearer token to the headers
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});


export const UpComingServiceAPISlice = createApi({
    reducerPath: "UpComingServiceAPI",
    baseQuery: baseQueryWithToken,

    endpoints: (builder) => ({
        getUpComingService: builder.query({
            query: () => "/api/v1/lead-services/get-upcoming-services",
            providesTags: ['upcomingService']
        }),
        addUpComingService: builder.mutation({
            query: (upcomingServiceData) => (console.log("\n upcomingServiceData >>>>> ", upcomingServiceData), {
                url: '/api/v1/admin/lead-services/create-upcoming-service',
                method: "POST",
                body: upcomingServiceData
            }),
            invalidatesTags: ['upcomingService']
        }),
        deleteUpComingService: builder.mutation({
            query: (id) => (console.log("\n delateUpComingService >>>> ", id), {
                url: `/api/v1/admin/lead-services/delete-upcoming-service/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['upcomingService']
        }),
        editUpComingInfoService: builder.mutation({
            query: (data) => ({
                url: `/api/v1/admin/lead-services/update-upcoming-service-info/${data?.id}`,
                method: 'PATCH',
                body: data?.editData
            }),
            invalidatesTags: ['upcomingService']
        }),
        editUpComingImageService: builder.mutation({
            query: (data) => ({
                url: `/api/v1/admin/lead-services/update-upcoming-service-image/${data?.id}`,
                method: 'PATCH',
                body: data?.editImageData
            }),
            invalidatesTags: ['upcomingService']
        })
    })
})

export const {
    useGetUpComingServiceQuery,
    useAddUpComingServiceMutation,
    useDeleteUpComingServiceMutation,
    useEditUpComingImageServiceMutation,
    useEditUpComingInfoServiceMutation
} = UpComingServiceAPISlice
import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithToken from "src/features/helper/apiHeader/ApiHeader";


export const ProfileAPISlice = createApi({
    reducerPath : "ProfileAPI",
    baseQuery: baseQueryWithToken,

    endpoints: (builder) => ({
        getAdminProfile : builder.query({
            query : () => "/admin/admin/get-admin-profile",
            providesTags : ['Profile']
        }),
    })
})

export const {
    useGetAdminProfileQuery
} = ProfileAPISlice
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import baseQueryWithToken from "src/features/helper/apiHeader/ApiHeader";


// const baseQueryWithToken = fetchBaseQuery({
//     baseUrl: "https://api.rupaylender.com/",
//     prepareHeaders: (headers, { getState }) => {
//         // Get the token from your state or wherever it's stored
//         // const token = getState().auth.token; // Adjust this according to your state structure
//         const token = sessionStorage.getItem('token')
//         if (token) {
//             // Add the bearer token to the headers
//             headers.set('Authorization', `Bearer ${token}`);
//         }
//         return headers;
//     },
// });


const baseQueryWithToken = fetchBaseQuery({
    baseUrl: "https://api.rupaylender.com/",
    prepareHeaders: (headers, { getState }) => {
        const token = sessionStorage.getItem('token')
        if (token) {
            // Add the bearer token to the headers
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});


export const OfferAPISlice = createApi({
    reducerPath: "OfferAPI",
    baseQuery: baseQueryWithToken,

    endpoints: (builder) => ({
        getOffer: builder.query({
            query: () => "/api/v1/offers/get-offers",
            providesTags: ['offer']
        }),
        addOffer: builder.mutation({
            query: (offerData) => ({
                url: '/api/v1/admin/offers/create-offer',
                method: "POST",
                body: offerData
            }),
            invalidatesTags: ['offer']
        }),
        updateInfoOffer: builder.mutation({
            query: (updateData) => (console.log("\n check  updateData >>>> ", updateData), {
                url: `/api/v1/admin/offers/update-offer-info/${updateData?.id}`,
                method: "PATCH",
                body: updateData?.updateInfo
            }),
            invalidatesTags: ['offer']
        }),
        updateImageOffer: builder.mutation({
            query: (data) => (console.log("\n data >>>> ", data), {
                url: `/api/v1/admin/offers/update-offer-image/${data.id}`,
                method: "PATCH",
                body: data?.updateImageData
            }),
            invalidatesTags: ['offer']
        }),
        // getSingleOffer: builder.mutation({
        //     query : (offerId) => ({
        //         url : `/offers/get-single-offer/${offerId}`,
        //         method : 'GET',
        //     }),
        //     providesTags : ['offer']
        // })
        getSingleOffer: builder.query({
            query: (offerId) => `/api/v1/offers/get-single-offer/${offerId}`,
            providesTags: ['offer']
        }),
        deleteOffer: builder.mutation({
            query: (id) => (console.log("id in  deleteOffer  >>> ", id), {
                url: `/api/v1/admin/offers/delete-offer/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['offer']
        })
    })
})

export const {
    useGetOfferQuery,
    useGetSingleOfferQuery,
    useDeleteOfferMutation,
    useAddOfferMutation,
    useUpdateInfoOfferMutation,
    useUpdateImageOfferMutation
} = OfferAPISlice
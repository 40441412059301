import React from 'react'

export const Base_URL = "https://api.rupaylender.com/api/v1";

const myToken = sessionStorage.getItem('token');
// let Base_URL; 
let token;

if (myToken) {
    token = myToken;
} else {
    token = null
}

const UpdateToken = (authToken) => {
    token = authToken
}

export { UpdateToken, token };

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import baseQueryWithToken from "src/features/helper/apiHeader/ApiHeader";


export const BasicInfoAPISlice = createApi({
    reducerPath : "BasicInfoAPI",
    baseQuery: baseQueryWithToken,

    endpoints: (builder) => ({
        // Occupation API
        getOccupation: builder.query({
            query : () => "/admin/basic-info/get-occupation",
            providesTags : ['Occupation']
        }),
        addOccupation: builder.mutation({
            query : (data) => ({
                url : '/admin/basic-info/set-occupation',
                method : "POST",
                body : data
            }),
            invalidatesTags : ['Occupation']
        }),
        updateOccupation : builder.mutation({
            query: (data) => ({
                url : `/admin/basic-info/update-occupation`,
                method : "PATCH",
                body: data
            }),
            invalidatesTags : ['Occupation']
        }),
        delateOccupation: builder.mutation({
            query: (id) => ({
                url : `/admin/basic-info/delete-occupation/${id}`,
                method: 'PATCH',
            }),
            invalidatesTags : ['Occupation']
        }) ,



        // Occupation API
        getQualification: builder.query({
            query : () => "/admin/basic-info/get-qualification",
            providesTags : ['Qualification']
        }),
        addQualification: builder.mutation({
            query : (data) => ({
                url : '/admin/basic-info/set-qualification',
                method : "POST",
                body : data
            }),
            invalidatesTags : ['Qualification']
        }),
        updateQualification : builder.mutation({
            query: (data) => ({
                url : `/admin/basic-info/update-qualification`,
                method : "PATCH",
                body: data
            }),
            invalidatesTags : ['Qualification']
        }),
        delateQualification : builder.mutation({
            query: (id) => ({
                url : `/admin/basic-info/delete-qualification/${id}`,
                method: 'PATCH',
            }),
            invalidatesTags : ['Qualification']
        }) ,


        // Annual Income API
        getAnnualIncome: builder.query({
            query : () => "/admin/basic-info/get-annual-income",
            providesTags : ['AnnualIncome']
        }),
        addAnnualIncome: builder.mutation({
            query : (data) => ({
                url : '/admin/basic-info/set-annual-income',
                method : "POST",
                body : data
            }),
            invalidatesTags : ['AnnualIncome']
        }),
        updateAnnualIncome : builder.mutation({
            query: (data) => ({
                url : `/admin/basic-info/update-annual-income`,
                method : "PATCH",
                body: data
            }),
            invalidatesTags : ['AnnualIncome']
        }),
        delateAnnualIncome : builder.mutation({
            query: (id) => ({
                url : `/admin/basic-info/delete-annual-income/${id}`,
                method: 'PATCH',
            }),
            invalidatesTags : ['AnnualIncome']
        })
    })
})

export const {
    useGetOccupationQuery,
    useAddOccupationMutation,
    useUpdateOccupationMutation,
    useDelateOccupationMutation,

    useGetQualificationQuery,
    useAddQualificationMutation,
    useUpdateQualificationMutation,
    useDelateQualificationMutation,

    useGetAnnualIncomeQuery,
    useAddAnnualIncomeMutation,
    useUpdateAnnualIncomeMutation,
    useDelateAnnualIncomeMutation
} = BasicInfoAPISlice
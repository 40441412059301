import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import baseQueryWithToken from "src/features/helper/apiHeader/ApiHeader";



export const brochureAPISlice = createApi({
    reducerPath: "brochureAPI",
    baseQuery: baseQueryWithToken,

    endpoints: (builder) => ({
        getBrochures: builder.query({
            query: () => "/admin/media/get-brochures",
            providesTags: ['brochures']
        }),
        addBrochures: builder.mutation({
            query: (brochuresData) => (console.log("\n brochuresData >>>> ", brochuresData), {
                url: '/admin/media/media-document',
                method: "POST",
                body: brochuresData
            }),
            invalidatesTags: ['brochures']
        }),
        delateBrochures: builder.mutation({
            query: (id) => (console.log("delete deleteBrochure >>>> ", id), {
                url: `/admin/media/media-document/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['brochures']
        }),
        updateBrochuresInfo: builder.mutation({
            query: (updateData) => (console.log("\n check  updateData >>>> ", updateData), {
                url: `/admin/media/media-document/${updateData?.id}`,
                method: "PATCH",
                body: updateData?.data
            }),
            invalidatesTags: ['brochures']
        }),
    })
})

export const {
    useGetBrochuresQuery,
    useAddBrochuresMutation,
    useUpdateBrochuresInfoMutation,
    useDelateBrochuresMutation
} = brochureAPISlice
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isAuthenticated : sessionStorage.getItem('isAuthenticated') || false,
    token : sessionStorage.getItem('token') || null,
}

const TokenSlice = createSlice({
    name : 'token',
    initialState,
    reducers: {
        tokenGenerate : (state, action) => {
            state.isAuthenticated = true,
            state.token = action.payload,

            sessionStorage.setItem('isAuthenticated', state.isAuthenticated),
            sessionStorage.setItem('token', state.token)
        },
        removeToken : (state) => {
            state.isAuthenticated = false,
            state.token = null

            sessionStorage.setItem('isAuthenticated', state.isAuthenticated),
            sessionStorage.setItem('token', state.token)
        }
    }
})

export const { tokenGenerate, removeToken } = TokenSlice.actions;
export default TokenSlice.reducer; 
import React from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableCaption,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'

import userImage from '../../../assets/images/userImage.png'
import panCard from '../../../assets/images/panCard.png'
import AadharCard from '../../../assets/images/AadharCard.png'

import { useParams } from 'react-router-dom';
import { useGetUserDetailsQuery } from 'src/features/integrateAPI/user/UserAPISlice'

const UserDetails = () => {

  // user id
  const { userId } = useParams();

  // payment details api
  const {data: userDetails , isError , isLoading } = useGetUserDetailsQuery(userId);

  
  // get date of birth
  const reverseDate = (dateString) => {
    const datePart = dateString.split('T')[0];
    const parts = datePart.split('-');

    const reversedParts = parts.reverse();

    const reversedDate = reversedParts.join('-');

    // return
    return reversedDate;
  }


  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <span className='font-Inter font-semibold text-lg text-[#3B3935]'>User detail</span>
          </CCardHeader>
          <CCardBody>
            {/* add this for hover effect on table href="components/table#hoverable-rows" */}
            <div href="components/table#hoverable-rows" >
                {/* for hover effect add hover attribute in CTable */}
              <CTable hover>
              {
                  (userDetails && userDetails.success) ?

              <CTableBody>
                 {
                  (userDetails.data[0]).name ? 
                  <CTableRow>
                    <CTableHeaderCell scope="col" className='lg:w-[40%] xl:[30%] font-Inter font-semibold text-base text-[#3B3935] py-3'>Name</CTableHeaderCell>
                    <CTableHeaderCell scope="col" className='font-Inter font-normal text-sm text-[#3B3935] py-3'>
                      {(userDetails.data[0]).name}
                    </CTableHeaderCell>
                </CTableRow>
                : null
                }
                {
                  (userDetails.data[0]).userDocs && ((userDetails.data[0]).userDocs).photo ?
                  <CTableRow>
                    <CTableHeaderCell scope="row" className='font-Inter font-semibold text-base text-[#3B3935] py-3'>Profile photo</CTableHeaderCell>
                    <CTableDataCell className='font-Inter font-normal text-sm text-[#3B3935] py-3'>
                        <img src={((userDetails.data[0]).userDocs).photo} className='w-[60px] h-[60px]' alt="" />
                    </CTableDataCell>
                  </CTableRow>
                  : null
                }
                 {
                  (userDetails.data[0]).emailId ? 
                  <CTableRow>
                    <CTableHeaderCell scope="col" className='lg:w-[40%] xl:[30%] font-Inter font-semibold text-base text-[#3B3935] py-3'>Email Id</CTableHeaderCell>
                    <CTableHeaderCell scope="col" className='font-Inter font-normal text-sm text-[#3B3935] py-3'>
                      {(userDetails.data[0]).emailId}
                    </CTableHeaderCell>
                </CTableRow>
                : null
                }


                {
                  (userDetails.data[0]).mobileNumber && ((userDetails.data[0]).mobileNumber).value ? 
                  <CTableRow>
                    <CTableHeaderCell scope="col" className='lg:w-[40%] xl:[30%] font-Inter font-semibold text-base text-[#3B3935] py-3'>Mobile Number</CTableHeaderCell>
                    <CTableHeaderCell scope="col" className='font-Inter font-normal text-sm text-[#3B3935] py-3'>
                      {((userDetails.data[0]).mobileNumber).value}
                    </CTableHeaderCell>
                </CTableRow>
                : null
                }
                 {
                  (userDetails.data[0]).pinCode ? 
                  <CTableRow>
                    <CTableHeaderCell scope="col" className='lg:w-[40%] xl:[30%] font-Inter font-semibold text-base text-[#3B3935] py-3'>Pin Code</CTableHeaderCell>
                    <CTableHeaderCell scope="col" className='font-Inter font-normal text-sm text-[#3B3935] py-3'>
                      {(userDetails.data[0]).pinCode}
                    </CTableHeaderCell>
                </CTableRow>
                : null
                } 
                {
                  (userDetails.data[0]).memberSince ? 
                  <CTableRow>
                    <CTableHeaderCell scope="col" className='lg:w-[40%] xl:[30%] font-Inter font-semibold text-base text-[#3B3935] py-3'>Member Since</CTableHeaderCell>
                    <CTableHeaderCell scope="col" className='font-Inter font-normal text-sm text-[#3B3935] py-3'>
                      {(userDetails.data[0]).memberSince}
                    </CTableHeaderCell>
                </CTableRow>
                : null
                }
                
                

                {
                  (userDetails.data[0]).occupation ? 
                  <CTableRow>
                    <CTableHeaderCell scope="col" className='lg:w-[40%] xl:[30%] font-Inter font-semibold text-base text-[#3B3935] py-3'>Occupation</CTableHeaderCell>
                    <CTableHeaderCell scope="col" className='font-Inter font-normal text-sm text-[#3B3935] py-3'>
                      {(userDetails.data[0]).occupation}
                    </CTableHeaderCell>
                </CTableRow>
                : null
                }
                    
                 {
                  (userDetails.data[0]).qualification ? 
                  <CTableRow>
                    <CTableHeaderCell scope="col" className='lg:w-[40%] xl:[30%] font-Inter font-semibold text-base text-[#3B3935] py-3'>Qualification</CTableHeaderCell>
                    <CTableHeaderCell scope="col" className='font-Inter font-normal text-sm text-[#3B3935] py-3'>
                      {(userDetails.data[0]).qualification}
                    </CTableHeaderCell>
                </CTableRow>
                : null
                }  
                {
                  (userDetails.data[0]).annualIncome ? 
                  <CTableRow>
                    <CTableHeaderCell scope="col" className='lg:w-[40%] xl:[30%] font-Inter font-semibold text-base text-[#3B3935] py-3'>Annual Income</CTableHeaderCell>
                    <CTableHeaderCell scope="col" className='font-Inter font-normal text-sm text-[#3B3935] py-3'>
                      {(userDetails.data[0]).annualIncome}
                    </CTableHeaderCell>
                </CTableRow>
                : null
                
                }
                 {
                  (userDetails.data[0]).gstNumber ? 
                  <CTableRow>
                    <CTableHeaderCell scope="col" className='lg:w-[40%] xl:[30%] font-Inter font-semibold text-base text-[#3B3935] py-3'>GST Number</CTableHeaderCell>
                    <CTableHeaderCell scope="col" className='font-Inter font-normal text-sm text-[#3B3935] py-3'>
                      {(userDetails.data[0]).gstNumber}
                    </CTableHeaderCell>
                </CTableRow>
                : null
                }  
                 {
                  (userDetails.data[0]).appInstalledStatus ? 
                  <CTableRow>
                    <CTableHeaderCell scope="col" className='lg:w-[40%] xl:[30%] font-Inter font-semibold text-base text-[#3B3935] py-3'>App Installed Status</CTableHeaderCell>
                    <CTableHeaderCell scope="col" className='font-Inter font-normal text-sm text-[#3B3935] py-3'>
                      {(userDetails.data[0]).appInstalledStatus}
                    </CTableHeaderCell>
                </CTableRow>
                : null
                }    
                

                {
                  (userDetails.data[0]).userDocs && ((userDetails.data[0]).userDocs).aadharCard ?
                  <CTableRow>
                    <CTableHeaderCell scope="row" className='font-Inter font-semibold text-base text-[#3B3935] py-3'>Aadhar card</CTableHeaderCell>
                    <CTableDataCell className='font-Inter font-normal text-sm text-[#3B3935] py-3'>
                        <img src={((userDetails.data[0]).userDocs).aadharCard} className='w-[60px] h-[60px]' alt="" />
                    </CTableDataCell>
                  </CTableRow>
                  : null
                }
                {
                  (userDetails.data[0]).userDocs && ((userDetails.data[0]).userDocs).panCard ?
                  <CTableRow>
                    <CTableHeaderCell scope="row" className='font-Inter font-semibold text-base text-[#3B3935] py-3'>Pan card</CTableHeaderCell>
                    <CTableDataCell className='font-Inter font-normal text-sm text-[#3B3935] py-3'>
                        <img src={((userDetails.data[0]).userDocs).panCard} className='w-[60px] h-[60px]' alt="" />
                    </CTableDataCell>
                  </CTableRow>
                  : null
                }
                
                {
                  (userDetails.data[0]).dob ? 
                  <CTableRow>
                    <CTableHeaderCell scope="col" className='lg:w-[40%] xl:[30%] font-Inter font-semibold text-base text-[#3B3935] py-3'>Date of Birth</CTableHeaderCell>
                    <CTableHeaderCell scope="col" className='font-Inter font-normal text-sm text-[#3B3935] py-3'>
                      {reverseDate((userDetails.data[0]).dob)}
                    </CTableHeaderCell>
                </CTableRow>
                : null
                }
                {
                  (userDetails.data[0]).status ? 
                  <CTableRow>
                    <CTableHeaderCell scope="row" className='font-Inter font-semibold text-base text-[#3B3935] py-3'>Status</CTableHeaderCell>
                    <CTableDataCell className='font-Inter font-normal text-sm text-[#3B3935] py-3'>
                        <span className='font-Inter font-normal text-sm text-[#FFFFFF] bg-[#33CC66] rounded-[2rem] px-3 py-2 capitalize'>{(userDetails.data[0]).status}</span>
                    </CTableDataCell>
                </CTableRow>
                : null
                }
                {
                  (userDetails.data[0]).loginStatus ? 
                  <CTableRow>
                    <CTableHeaderCell scope="row" className='font-Inter font-semibold text-base text-[#3B3935] py-3'>Login Status</CTableHeaderCell>
                    <CTableDataCell className='font-Inter font-normal text-sm text-[#3B3935] py-3'>
                        <span className='font-Inter font-normal text-sm text-[#FFFFFF] bg-[#33CC66] rounded-[2rem] px-3 py-2 capitalize'>{(userDetails.data[0]).loginStatus}</span>
                    </CTableDataCell>
                </CTableRow>
                : null
                }
                

                </CTableBody>
              : null }





              {/* <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell scope="col" className='w-[40%] font-Inter font-semibold text-base text-[#3B3935] py-3'>Name</CTableHeaderCell>
                    <CTableHeaderCell scope="col" className='font-Inter font-normal text-sm text-[#3B3935] py-3'>Dixita agraval</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  <CTableRow>
                    <CTableHeaderCell scope="row" className='font-Inter font-semibold text-base text-[#3B3935] py-3'>Email</CTableHeaderCell>
                    <CTableDataCell className='font-Inter font-normal text-sm text-[#3B3935] py-3'>dixita123@gmail.com</CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableHeaderCell scope="row" className='font-Inter font-semibold text-base text-[#3B3935] py-3'>Mobile Number</CTableHeaderCell>
                    <CTableDataCell className='font-Inter font-normal text-sm text-[#3B3935] py-3'>5258256325</CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableHeaderCell scope="row" className='font-Inter font-semibold text-base text-[#3B3935] py-3'>User code</CTableHeaderCell>
                    <CTableDataCell className='font-Inter font-normal text-sm text-[#3B3935] py-3'>ec2546258</CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableHeaderCell scope="row" className='font-Inter font-semibold text-base text-[#3B3935] py-3'>Last Login</CTableHeaderCell>
                    <CTableDataCell className='font-Inter font-normal text-sm text-[#3B3935] py-3'>Jan 13 2023</CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableHeaderCell scope="row" className='font-Inter font-semibold text-base text-[#3B3935] py-3'>Profile photo</CTableHeaderCell>
                    <CTableDataCell className='font-Inter font-normal text-sm text-[#3B3935] py-3'>
                        <img src={userImage} className='w-[60px] h-[60px]' alt="" />
                    </CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableHeaderCell scope="row" className='font-Inter font-semibold text-base text-[#3B3935] py-3'>Occupation</CTableHeaderCell>
                    <CTableDataCell className='font-Inter font-normal text-sm text-[#3B3935] py-3'>Salaried</CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableHeaderCell scope="row" className='font-Inter font-semibold text-base text-[#3B3935] py-3'>Qualification</CTableHeaderCell>
                    <CTableDataCell className='font-Inter font-normal text-sm text-[#3B3935] py-3'>Post Graduate</CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableHeaderCell scope="row" className='font-Inter font-semibold text-base text-[#3B3935] py-3'>Pin Code</CTableHeaderCell>
                    <CTableDataCell className='font-Inter font-normal text-sm text-[#3B3935] py-3'>3950010</CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableHeaderCell scope="row" className='font-Inter font-semibold text-base text-[#3B3935] py-3'>Date of Birth</CTableHeaderCell>
                    <CTableDataCell className='font-Inter font-normal text-sm text-[#3B3935] py-3'>03-05-1998</CTableDataCell>
                  </CTableRow>
                
                  <CTableRow>
                    <CTableHeaderCell scope="row" className='font-Inter font-semibold text-base text-[#3B3935] py-3'>Aadhar card</CTableHeaderCell>
                    <CTableDataCell className='font-Inter font-normal text-sm text-[#3B3935] py-3'>
                        <img src={AadharCard} className='w-[125px] h-[60px]' alt="" />
                    </CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableHeaderCell scope="row" className='font-Inter font-semibold text-base text-[#3B3935] py-3'>Pan card</CTableHeaderCell>
                    <CTableDataCell className='font-Inter font-normal text-sm text-[#3B3935] py-3'>
                        <img src={panCard} className='w-[125px] h-[60px]' alt="" />
                    </CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableHeaderCell scope="row" className='font-Inter font-semibold text-base text-[#3B3935] py-3'>GST NO</CTableHeaderCell>
                    <CTableDataCell className='font-Inter font-normal text-sm text-[#3B3935] py-3'>2514523</CTableDataCell>
                  </CTableRow>


                  <CTableRow>
                    <CTableHeaderCell scope="row" className='font-Inter font-semibold text-base text-[#3B3935] py-3'>Status</CTableHeaderCell>
                    <CTableDataCell className='font-Inter font-normal text-sm text-[#3B3935] py-3'>
                        <button className='font-Inter font-normal text-sm text-[#FFFFFF] bg-[#33CC66] rounded-[2rem] px-3 py-2'>Active</button>
                    </CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableHeaderCell scope="row" className='font-Inter font-semibold text-base text-[#3B3935] py-3'>Verification</CTableHeaderCell>
                    <CTableDataCell className='font-Inter font-normal text-sm text-[#3B3935] py-3'>
                        <button className='font-Inter font-normal text-sm text-[#FFFFFF] bg-[#33CC66] rounded-[2rem] px-3 py-2'>Verified</button>
                    </CTableDataCell>
                  </CTableRow>
                </CTableBody> */}

              </CTable>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default UserDetails;

import { BusinessLoanAPISlice } from './businessLoan/BusinessLoanAPISlice';
import { CarLoanAPISlice } from './carLoan/CarLoanAPISlice';
import { CreditCardsAPISlice } from './creditCards/CreditCardsAPISlice';
import { HomeLoanAPISlice } from './homeLoan/HomeLoanAPISlice';
import { PersonalLoanAPISlice } from './personalLoan/PersonalLoanAPISlice';
// Import other API slices...

const LeadServicesAPISlice = {
    CreditCardsAPISlice,
    HomeLoanAPISlice,
    BusinessLoanAPISlice,
    CarLoanAPISlice,
    PersonalLoanAPISlice,
};

export default LeadServicesAPISlice;
import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithToken from "src/features/helper/apiHeader/ApiHeader";


export const WithDrawalAPISlice = createApi({
    reducerPath : "WithDrawalAPI",
    baseQuery: baseQueryWithToken,

    endpoints: (builder) => ({
        getPaymenthistoryList: builder.query({
            query : () => "/admin/paymenthistory/payment-history-list",
            providesTags : ['WithDrawal']
        }),
        getPaymenthistoryDetails : builder.query({
            query : (id) => `/admin/paymenthistory/payment-history-details/${id}`,
        }),
        updatePaymenthistoryDetails: builder.mutation({
            query : (updatedPaymantData) => ({
                url : '/admin/paymenthistory/update-payment-history-details',
                method : "PATCH",
                body : updatedPaymantData
    //             "status":"success",
    // "transactionId":"66039e4adf791889",
    // "paymentHistoryId":"66039e4adf791889829c46e1"

            }),
            invalidatesTags : ['WithDrawal']
        }),
    })
})

export const {
    useGetPaymenthistoryListQuery,
    useGetPaymenthistoryDetailsQuery,
    useUpdatePaymenthistoryDetailsMutation
} = WithDrawalAPISlice
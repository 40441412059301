import { Base_URL } from "../apiUrl/ApiUrl";
import { token } from "../apiUrl/ApiUrl";

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQueryWithToken = fetchBaseQuery({
    baseUrl: Base_URL,
    prepareHeaders: (headers) => {
        if (token) {
            // Add the bearer token to the headers
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

export default baseQueryWithToken;
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import baseQueryWithToken from "src/features/helper/apiHeader/ApiHeader";


export const CarLoanAPISlice = createApi({
    reducerPath: "CarLoanAPI",
    baseQuery: baseQueryWithToken,

    endpoints: (builder) => ({
        getAllCarLoan: builder.query({
            query: () => "/admin/lead-services/car-loan/car-loans",
            providesTags: ["CarLoan"]
        }),
        createCarLoan: builder.mutation({
            query: (carDetails) => (console.log("\n carDetails  >>>>>> ", carDetails), {
                url: '/admin/lead-services/car-loan/create-car-loan',
                method: "POST",
                body: carDetails
            }),
            invalidatesTags: ['CreditCards']
        }),
        updateSellServicesImage: builder.mutation({
            query: (data) => ({
                url: `/admin/lead-services/update-sell-service-image/${data.id}`,
                method: "PATCH",
                body: data.uploadImageData
            }),
            invalidatesTags: ['SellServices']
        }),
        updateCarLoan: builder.mutation({
            query: (update) => (console.log("\n updateCarLoan >>>>>> ", update), {
                url: `/admin/lead-services/credit-card/update-credit-card/${update.id}`,
                method: "PATCH",
                body: update.data
            }),
            invalidatesTags: ['CreditCards']
        }),
        deleteCarLoan: builder.mutation({
            query: (id) => ({
                url: `/admin/lead-services/car-loan/delete-car-loan/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["CarLoan"]
        }),
        addCreditCardFAQ: builder.mutation({
            query: (createCreditCardfaq) => ({
                url: `/admin/lead-services/credit-card/add-faq/${id}`,
                method: "POST",
                body: createCreditCardfaq
            }),
            invalidatesTags: ['CreditCards']
        }),
        getgetSingleCarLoan: builder.query({
            query: (id) => `/admin/lead-services/car-loan/single-car-loan/${id}`,
        }),
    })
})

export const {
    useGetAllCarLoanQuery,
    useGetgetSingleCarLoanQuery,
    useDeleteCarLoanMutation,
    useCreateCarLoanMutation,
    useUpdateCarLoanMutation

} = CarLoanAPISlice
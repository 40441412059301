// valueSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedProduct : 'Credit Card',
    showAddSellServices : false,
    sellServicesList : sessionStorage.getItem('sellServicesList') ? JSON.parse(sessionStorage.getItem('sellServicesList')) : [],
    selectedLeads : 'Credit Card'
};

const valueSlice = createSlice({
    name: 'values',
    initialState,
    reducers: {
        // set the selectd product value
        setSelectedProduct : (state, action)=>{
            state.selectedProduct = action.payload
        },
        // set show add sell services
        setShowAddSellServices : (state, action) =>{
            state.showAddSellServices = action.payload
        },
        // add how many sell sevices we have selected
        setSellServicesList : (state, action)=>{
            state.sellServicesList = action.payload,
            sessionStorage.setItem('sellServicesList', JSON.stringify(state.sellServicesList))
        },
        setSelectedLeads : (state, action)=>{
            state.selectedLeads = action.payload
        },

    },
});

export const { setSelectedProduct, setShowAddSellServices, setSellServicesList, setSelectedLeads } = valueSlice.actions;
export default valueSlice.reducer;

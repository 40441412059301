import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithToken from "src/features/helper/apiHeader/ApiHeader";


export const UserAPISlice = createApi({
    reducerPath : "UserAPI",
    baseQuery: baseQueryWithToken,

    endpoints: (builder) => ({
        getUserList: builder.query({
            query : () => "/admin/users/usersList",
            providesTags : ['User']
        }),
        getUserDetails : builder.query({
            query : (id) => `/admin/users/userDetails/${id}`,
            providesTags : ['User']
        }),
        updateUserDetails: builder.mutation({
            query : (updatedUserData) => ({
                url : '/admin/users/userDetails',
                method : "PATCH",
                body : updatedUserData
            }),
            invalidatesTags : ['User']
        }),
    })
})

export const {
    useGetUserListQuery,
    useGetUserDetailsQuery,
    useUpdateUserDetailsMutation
} = UserAPISlice;
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


const baseQueryWithToken = fetchBaseQuery({
    baseUrl: "https://api.rupaylender.com/",
    prepareHeaders: (headers, { getState }) => {
        const token = sessionStorage.getItem('token')
        if (token) {
            // Add the bearer token to the headers
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});


export const OnboardingAPISlice = createApi({
    reducerPath: "OnboardingAPI",
    baseQuery: baseQueryWithToken,

    endpoints: (builder) => ({
        getOnboarding: builder.query({
            query: () => "/api/v1/admin/home/on-boarding",
            providesTags: ['onboarding']
        }),
        addOnboarding: builder.mutation({
            query: (onboardingData) => ({
                url: '/api/v1/admin/home/on-boarding',
                method: "POST",
                body: onboardingData
            }),
            invalidatesTags: ['onboarding']
        }),
        delateOnboarding: builder.mutation({
            query: (id) => ({
                url: `/api/v1/admin/home/on-bording/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['onboarding']
        })
    })
})

export const {
    useGetOnboardingQuery,
    useAddOnboardingMutation,
    useDelateOnboardingMutation
} = OnboardingAPISlice
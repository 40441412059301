import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import baseQueryWithToken from "src/features/helper/apiHeader/ApiHeader";


export const BusinessLoanAPISlice = createApi({
    reducerPath: "BusinessLoanAPI",
    baseQuery: baseQueryWithToken,

    endpoints: (builder) => ({
        getAllBusinessLoan: builder.query({
            query: () => "/admin/lead-services/business-loan/business-loans",
            providesTags: ["BusinessLoan"]
        }),
        createBusinessLoan: builder.mutation({
            query: (businessLoanDetails) => (console.log("\n businessLoanDetails >>>> ", businessLoanDetails), {
                url: '/admin/lead-services/business-loan/create-business-loan',
                method: "POST",
                body: businessLoanDetails
            }),
            invalidatesTags: ['BusinessLoan']
        }),
        updateSellServicesImage: builder.mutation({
            query: (data) => ({
                url: `/admin/lead-services/update-sell-service-image/${data.id}`,
                method: "PATCH",
                body: data.uploadImageData
            }),
            invalidatesTags: ['SellServices']
        }),
        updateBusinessLoan: builder.mutation({
            query: (update) => (console.log("\n updateBusinessLoan >>>>>> ", update), {
                url: `/admin/lead-services/business-loan/update-business-loan/${update.id}`,
                method: "PATCH",
                body: update.data
            }),
            invalidatesTags: ['BusinessLoan']
        }),
        deleteBusinessLoan: builder.mutation({
            query: (id) => ({
                url: `/admin/lead-services/business-loan/delete-business-loan/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["BusinessLoan"]
        }),
        addCreditCardFAQ: builder.mutation({
            query: (createCreditCardfaq) => ({
                url: `/admin/lead-services/credit-card/add-faq/${id}`,
                method: "POST",
                body: createCreditCardfaq
            }),
            invalidatesTags: ['CreditCards']
        }),
        getSingleBusinessLoan: builder.query({
            query: (id) => `/admin/lead-services/business-loan/single-business-loan/${id}`,
        }),
    })
})

export const {
    useGetAllBusinessLoanQuery,
    useGetSingleBusinessLoanQuery,
    useDeleteBusinessLoanMutation,
    useCreateBusinessLoanMutation,
    useUpdateBusinessLoanMutation

} = BusinessLoanAPISlice
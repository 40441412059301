import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


export const GenerateTokenAPISlice = createApi({
    reducerPath: "GenerateTokenAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://api.rupaylender.com/",
    }),

    endpoints: (builder) => ({
        generateToken: builder.mutation({
            query: (MydeviceId) => ({
                url: '/api/v1/common/generate-token',
                method: "POST",
                body: {
                    "deviceId": MydeviceId,
                    "role": "USER"
                }
            })
        }),
    })
})

export const {
    useGenerateTokenMutation
} = GenerateTokenAPISlice

import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithToken from "src/features/helper/apiHeader/ApiHeader";


export const LeadAPISlice = createApi({
    reducerPath : "LeadAPI",
    baseQuery: baseQueryWithToken,

    endpoints: (builder) => ({
        getLeadList: builder.query({
            query : (sellServiceData) => `/admin/lead-services/sold-service?sellServiceName=${sellServiceData.sellServiceName}&sellServiceId=${sellServiceData.sellServiceId}`,
            providesTags : ['Lead']
        }),
        getLeadDetails : builder.query({
            query : (id) => `/admin/lead-services/single-sold-service/${id}`,
            providesTags : ['Lead']
        }),
        updateLeadDetails: builder.mutation({
            query : (updatedLeadData) => ({
                url : '/admin/lead-services/sold-service',
                method : "PATCH",
                body : updatedLeadData
            }),
            invalidatesTags : ['Lead']
        }),
    })
})

export const {
    useGetLeadListQuery,
    useGetLeadDetailsQuery,
    useUpdateLeadDetailsMutation
} = LeadAPISlice;